var getDashboardUrl = function (metaSiteId) {
    return "https://manage.wix.com/dashboard/".concat(metaSiteId, "/blog");
};
export var editPostInDashboard = function (_a) {
    var postId = _a.postId, metaSiteId = _a.metaSiteId;
    return window.open("".concat(getDashboardUrl(metaSiteId), "/").concat(postId, "/edit"), '_blank', 'noopener');
};
export var createPostInDashboard = function (_a) {
    var metaSiteId = _a.metaSiteId, lang = _a.lang;
    return window.open("".concat(getDashboardUrl(metaSiteId), "/create-post").concat(lang ? "?lang=".concat(lang) : ''), '_blank', 'noopener');
};
